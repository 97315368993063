import React, { useState } from 'react'
import Slider from 'react-slick';
import { ContactMoadal, Layout } from '../../Components';
import validator from 'validator';
import { 
  aclsblskansascity,
  aclsblslouisville,
  aclsstlouis,
    anthemseniorliving,
    AssiteImage, assitIconFour, assitIconOne, assitIconThree, assitIconTwo, cprnashvilletn, cprtampa, 
    curbappeallawn, emailImageBig, 
    FeaturedLogoFive, FeaturedLogoOne, FeaturedLogoSix, FeaturedLogoThree, FeaturedLogoTwo, GoogleAdsLogo, 
    googleSearch, MetaPlatformsLogo, OperateIconFive, OperateIconFour, OperateIconOne, OperateIconSix, 
    OperateIconThree, OperateIconTwo, OperateImage, SemrushLogo, ServicesFive, ServicesFour, ServicesOne,
    ServicesSix, ServicesThree, ServicesTwo, star, userImage, 
    video, YelpLogo, YoastLogo, facebookslider, instagramslider, linkedinslider, twitterslider
  } from '../../Assets';
import { Link, useNavigate } from 'react-router-dom';
import { Disclosure, Transition } from '@headlessui/react';
import {GoPlus} from 'react-icons/go';
import {TiMinus} from 'react-icons/ti';
import {FaSkype} from 'react-icons/fa';
import {HiOutlineMail} from 'react-icons/hi';
import axios from 'axios';
import {BsWhatsapp} from 'react-icons/bs';
import { useFormik } from 'formik';

export default function HomePage(){

  const [websiteAnalysisModal, setWebsiteAnalysisModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const navigate = useNavigate();
  const [isDate, setIsDate] = useState(false);
    
    const HandelWebAnalysModal = (val) => {
      setWebsiteAnalysisModal(true);
      setModalTitle(val);
    } 
    const HandelCloseModal = (val) => {
      setWebsiteAnalysisModal(val);
      setIsDate(false);
    }

  const [thankYou, setThankYou] = useState(false);
  const [error, setError] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);

  const [webThankYou, setWebThankYou] = useState(false);
  const [webError, setWebError] = useState(false);

  const [mobThankYou, setMobThankYou] = useState(false);
  const [mobError, setMobError] = useState(false);
  

  var marketersSlider = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };

  var portfolioSlider = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    autoplay: true,
    centerPadding: "60px",
    speed: 1000,
    autoplaySpeed: 2000,
    initialSlide: 0,
    responsive: [
     
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
      
    ]
  };

  const marketersLogo = [GoogleAdsLogo, MetaPlatformsLogo, SemrushLogo, YelpLogo, YoastLogo, facebookslider, instagramslider, linkedinslider, twitterslider];
  const featuredLogo = [FeaturedLogoOne, FeaturedLogoTwo, FeaturedLogoThree, FeaturedLogoFive, FeaturedLogoSix, YoastLogo, facebookslider, instagramslider, linkedinslider, twitterslider]

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = 'Please enter your name!';
    } else if (values.name.match("[<>]")) {
      errors.name = 'Please provide a valid name';
    }

    if (!values.email) {
      errors.email = 'Please enter email address!';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }

    if (values.phone) {
      if (!validator.isMobilePhone(values.phone)){
        errors.phone = 'Please enter valid phone number!';
      }
    }

    if (!values.web) {
      errors.web = 'Please enter web address!';
    } else if (!validator.isURL(values.web)){
      errors.web = 'Please enter valid web address!';
    }

    if (!values.message) {
      errors.message = 'Please enter message!';
    } else if (values.message.match("[<>]")) {
      errors.message = 'Please provide a valid message';
    }

    return errors;
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      web: '',
      interest: '',
      skype: '',
      message: '',
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      setBtnLoader(true);
      setIsSubmit(true);

      const hiringFormData = {
        email: values.email,
        fullname:  values.name,
        phoneno: values.phone,
        interestedin: values.interest,
        website: values.web,
        skype: values.skype,
        message: values.message,
      }
      // https://works-reddensoft.com/reddensite/api/rankwebzformemail 
      axios.post('https://rankwebz.com/admin/api/storewehiring', hiringFormData)
          .then((res) => {
            setThankYou(true);
            navigate('/thank-you');
            resetForm();
          })
          .catch((errors) => {
            setError(true);
          })
          .finally(() => {
            setBtnLoader(false);
            setIsSubmit(false);
            setTimeout(() => {
              ErrorRemove()
            }, 3000);
          })

    }
  });

  const ErrorRemove = () => {
    setThankYou(false);
    setError(false);
    setWebThankYou(false);
    setWebError(false);
    setMobThankYou(false);
    setMobError(false);
  }

  const formikWeb = useFormik({
    initialValues: {
      email: '',
    },

    validate: values => {
      const errors = {};
      if (!values.email) {
        errors.email = 'Please enter website & contact email!';
      } else if (!validator.isURL(values.email)){
        errors.email = 'Please enter valid website & contact email!';
      }
      return errors;
    },
    onSubmit: (values, { resetForm }) => {
      setIsSubmit(true);
      // https://works-reddensoft.com/reddensite/api/rankwebzmailsend
      axios.post('https://rankwebz.com/admin/api/storeproposal', values)
      .then((res) => {
        setWebThankYou(true);
        resetForm();
        navigate('/thank-you');
      })
      .catch((errors) => {
        setWebError(true);
      })
      .finally(() => {
        setIsSubmit(false);
        setTimeout(() => {
          ErrorRemove()
        }, 3000);
      })
    }
  });

  const formikMob = useFormik({
    initialValues: {
      phonenumber: '',
    },

    validate: values => {
      const errors = {};
      if (!values.phonenumber) {
        errors.phonenumber = 'Please enter phone number!';
      } else if (!validator.isMobilePhone(values.phonenumber)){
        errors.phonenumber = 'Please enter valid phone number!';
      }
      return errors;
    },
    onSubmit: (values, { resetForm }) => {
      setIsSubmit(true);
      // https://works-reddensoft.com/reddensite/api/rankwebzphonesend
      axios.post('https://rankwebz.com/admin/api/storeintrocall', values)
      .then((res) => {
        setMobThankYou(true);
        resetForm();
        navigate('/thank-you');
      })
      .catch((errors) => {
        setMobError(true);
      })
      .finally(() => {
        setIsSubmit(false);
        setTimeout(() => {
          ErrorRemove()
        }, 3000);
      })
    }
  });

  const NumberConvart = (val) => {
    let newVal = val.replace(/[^0-9.+]/g, '');
    return newVal;
  }

  return (
    <Layout>
      <section  className='relative z-10 py-10'>
        <div className='container text-center'>
          <div className='max-w-3xl mx-auto md:pt-10'>
            <h1 className='text-3xl font-bold md:text-5xl sm:text-4xl text-gradient'>
              Boost Your Revenue With Stellar Efforts
            </h1>
            <p className='pt-3 text-sm md:text-lg md:pt-5'>
              Get ready to demystify the tricks associated with data-driven marketing 
              strategies and bolster your business growth to a next level on a sustainable basis.
            </p>

            <div className='relative max-w-lg mx-auto mt-7 md:mt-10'>
              <form onSubmit={formikWeb.handleSubmit}>
                <input type='text'
                name='email'
                onChange={formikWeb.handleChange}
                value={formikWeb.values.email}
                onBlur={formikWeb.handleBlur}
                className='w-full py-2 pl-5 text-sm bg-white border border-gray-500 outline-none pr-44 md:pr-56 rounded-3xl md:py-3 focus:border-primary' placeholder='Enter your website & contact email' /> 
                <div className='absolute top-0 bottom-0 right-0'>
                  <button disabled={isSubmit} type='submit' className='h-full text-sm text-white bg-gradient-coustom btn md:text-base'>Send a proposal</button>
                </div>
              </form>
            </div>
            {formikWeb.errors.email && formikWeb.touched.email && (
              <p className='mt-1 text-sm text-red-400'>
                {formikWeb.errors.email}
              </p>
            )}
            {webThankYou && (
              <div className='px-5 py-2 text-base font-bold text-center text-green-500 bg-white rounded col-span-full'>
                Thank You For Getting In Touch With RankWebz
              </div>
            )}

            {webError && (
              <div className='px-5 py-2 text-base text-center text-red-500 bg-white rounded col-span-full'>
                Network Error
              </div>
            )}

          </div>
        </div>
      </section>

      <section className='relative z-10 md:py-10 py-7'>
        <div className='container'>
          <p className='tracking-widest text-center'>Our marketers are trusted by</p>

          <div className='max-w-4xl px-5 pt-3 mx-auto md:pt-5 md:px-0 marketersSlider'>
            <Slider {...marketersSlider}>
              {marketersLogo && marketersLogo.length > 0 && marketersLogo.map((items, i) => {
                return(
                  <div key={i} className='px-4 text-center'>
                    <div className='flex items-center justify-center w-full h-16'>
                      <img src={items} alt=''/>
                    </div>
                  </div>
                )
              })}
            </Slider>
          </div>
        </div>
      </section>

      <section className='pt-10 mt-5 lg:pt-64 sm:pt-20 ms:mt-10 deliver-outcomes-section'>
        <div className='container relative z-10'>
          <div className='grid items-end md:grid-cols-2'>
            <div className='col-span-1'>
              <h2 className='font-bold leading-snug text-white lg:text-5xl md:text-4xl text-3xl lg:pr-10'>
                Leading Brands<br/> Confide In <span className='inline-block px-5 bg-gradient-coustom'>RankWebz</span> To Deliver Outcomes
              </h2>
              <p className='pt-5 text-white/70'>
                We have aided companies all around the globe realize search engines as one of their most valuable marketing tools by demonstrating our passion for quantifiable lead creation tactics.
              </p>

              <ul className='pt-10 pb-10 space-y-5 text-white list-items md:space-y-7'>
                <li>Higher Ranking In Search Engine</li>
                <li>Constant Flow Of Top-Notch Leads</li>
                <li>Break Past Digital Bottlenecks To Attract Clients Sustainably</li>
              </ul>
            </div>
            <div className='text-center md:text-right'>
              <img className='md:ml-auto' src={googleSearch} alt=''/>
            </div>
          </div>
        </div>
      </section>

      <section className='py-4 bg-gradient-coustom'>
        <div className='container relative z-10'>
            <div className='grid items-center lg:grid-cols-5 lg:gap-x-10'>
              <div className='flex items-center lg:col-span-2'>
                <div className='w-24 h-24 p-2 rounded-full bg-white/30'>
                  <div className='w-full h-full rounded-full '>
                      <img src={userImage} className='object-cover' alt=''/>
                  </div>
                </div>
                <div className='flex-1 pl-5'>
                  <p className='pb-2 text-white'>Ready to speak with a marketing expert?</p>
                  <img src={emailImageBig} alt=''/>
                </div>
              </div>
              <div className='flex items-center pt-5 space-x-3 divide-x lg:col-span-3 lg:pt-0 sm:space-x-7 lg:justify-end divide-white/20'>
                <div className='text-white '>
                  <p className='text-xl font-bold sm:text-3xl '>1.2 million</p>
                  <p className='pt-2 text-sm sm:text-base text-white/80'>Hours of Expertise</p>
                </div>
                <div className='pl-3 text-white sm:pl-7'>
                  <p className='text-xl font-bold sm:text-3xl '>150+</p>
                  <p className='pt-2 text-sm sm:text-base text-white/80'>Digital Marketing Masters</p>
                </div>

                <div className='pl-3 text-white sm:pl-7'>
                  <p className='text-xl font-bold sm:text-3xl '>900</p>
                  <p className='pt-2 text-sm sm:text-base text-white/80'>Success Projects</p>
                </div>
              </div>
            </div>
        </div>
      </section>

      <section className='pt-10 md:pt-20 md:pb-24 pb-14'>
        <div className='container'>
          <div className='grid items-center lg:grid-cols-5'>
            <div className='lg:col-span-3'>
              <p className='font-bold'>Defining Digital Marketing Strategies Based On Proven Outcomes</p>
              <h2 className='pr-10 text-3xl font-bold leading-snug text-black md:text-5xl'>
                How Can We Assist You?
              </h2>
              <p className='pt-2 md:pt-4'>An improved alliance for every company trying to propel digitally</p>

              <div className='grid gap-10 pt-12 pr-10 md:grid-cols-2'>
                <div className='flex '>
                  <div className='flex items-center justify-center rounded-sm w-14 h-14 bg-primary'>
                    <img src={assitIconThree} alt=''/>
                  </div>
                  <p className='flex-1 pl-4 text-lg font-bold'>
                    Dedicated support <br/>teams
                  </p>
                </div>

                <div className='flex '>
                  <div className='flex items-center justify-center rounded-sm w-14 h-14 bg-primary'>
                      <img src={assitIconFour} alt=''/>
                  </div>
                  <p className='flex-1 pl-4 text-lg font-bold'>
                    High level of assurance with proven outcomes
                  </p>
                </div>


                <div className='flex '>
                  <div className='flex items-center justify-center rounded-sm w-14 h-14 bg-primary'>
                    <img src={assitIconOne} alt=''/>
                  </div>
                  <p className='flex-1 pl-4 text-lg font-bold'>
                    24X7 technical <br/>support
                  </p>
                </div>


                <div className='flex '>
                  <div className='flex items-center justify-center rounded-sm w-14 h-14 bg-primary'>
                    <img src={assitIconTwo} alt=''/>
                  </div>
                  <p className='pl-4 text-lg font-bold'>
                    Timely completion of <br/>projects
                  </p>
                </div>
              </div>

              <div className='inline-flex items-center mt-16 border border-black'>
                <div className='bg-black p-1 relative before:absolute before:top-0 before:bottom-0 before:left-full before:border-l-[20px] 
                before:border-t-[18px] before:border-l-black before:border-b-[18px] before:border-b-transparent before:border-t-transparent'>
                    <img src={star} alt=""/>
                </div> 
                <p className='px-4 py-1 pl-8 text-sm'>(4.5 based on 1000 reviews)</p>
              </div>
            </div>
            <div className='pt-10 lg:col-span-2'>
              <img src={AssiteImage} alt=""/>
            </div>
          </div>
        </div>
      </section>

      <section className='relative my-10 before:absolute before:bg-secondary-50/40 lg:before:top-0 before:top-20 before:bottom-0 before:right-0 lg:before:w-4/6 before:w-full before:rounded-l-lg '>
        <div className='container relative z-10'>
            <div className='grid items-center lg:grid-cols-2 gap-x-10'>
                <div className='lg:pr-5 '>
                  <video className='rounded-lg' width="100%" height="100%" controls>
                    <source src={video} type="video/mp4" />
                  </video>
                </div>

                <div className='pt-5 pb-10 lg:pt-20 lg:pb-20'>
                  <h2 className='text-4xl font-bold leading-snug text-black lg:text-5xl lg:pr-10'>
                    Driving High & Impactful Metrics
                  </h2>
                  <p className='pt-4 pr-10'>
                    The clients are astounded by the exceptional services provided 
                    by our top-tier digital marketing experts, which wows them with powerful results:
                  </p>

                  <ul className='pt-10 pb-8 space-y-4 text-black list-items'>
                    <li>Organic session growth by 236%</li>
                    <li>PPC cost per lead is 90% lower </li>
                    <li>Increase in website traffic by 4X</li>
                    <li>Organic transaction growth by 198%</li>
                    
                  </ul>

                  <Link className='text-center text-white border btn w-52 bg-gradient-coustom'>
                    <span className='relative z-10'>Meet RankWebz</span>
                  </Link>

                   
                </div>
            </div>
        </div>
      </section>

      <section className='py-8 mt-10 lg:py-24 lg:mt-0'>
          <div className='container'>
              <div>
                <h2 className='pr-24 text-3xl font-bold leading-snug text-black lg:text-5xl sm:text-4xl md:pl-4'>
                  Our SEO Portfolio
                </h2>
              </div> 
              <div className='pt-10 portfolioSlider'>
                <Slider {...portfolioSlider}>
                  
                <div className='md:px-4'>
                  <div className='rounded-lg shadow-xl'>
                    <div className='flex px-4 py-4 overflow-hidden rounded-t-lg md:px-8 bg-gradient-coustom'>
                      <div className='relative z-10 flex items-center'>
                        <div className='md:w-20 md:h-20 w-16 h-16 rounded-full bg-white md:p-4 p-2.5  flex justify-center items-center'>
                          <img src={aclsstlouis} alt=""/>
                        </div>
                        <div className='flex-1 pl-5 text-white'>
                          <p>Project:</p>
                          <p className='text-lg font-bold'>https://aclsstlouis.com</p>
                        </div>
                      </div>
                    </div>
                    <div className='px-4 py-4 pb-8 bg-white rounded-b-lg md:px-8'>
                        <p>
                        CPR St. Louis is an American Heart Association affiliated training center. They have attained #1 ranking spot and captured market with higher lead generation. Our digital marketing strategies and Social Media Marketing have boosted their performance. 
                        </p>

                        <div className='grid grid-cols-7 pt-5 text-sm gap-x-2'>
                          <div className='col-span-4'>
                            <p className='font-bold text-primary'>Keyword</p>
                          </div>
                          <div className='col-span-1'>
                            <p className='font-bold text-primary'>Rank</p>
                          </div>
                          <div className='col-span-2'>
                            <p className='font-bold text-primary'>Search Engine</p>
                          </div>
                        </div>

                        <div className='pt-1 space-y-2 text-sm divide-y'>
                          <div className='grid grid-cols-7 pt-2 gap-x-2'>
                            <div className='col-span-4'>
                              <p className=''>acls certification st louis</p>
                            </div>
                            <div className='col-span-1'>
                              <p className=''>1</p>
                            </div>
                            <div className='col-span-2'>
                              <p className=''>www.google.com</p>
                            </div>
                          </div>

                          <div className='grid grid-cols-7 pt-2 gap-x-2'>
                            <div className='col-span-4'>
                              <p className=''>cpr certification st. louis</p>
                            </div>
                            <div className='col-span-1'>
                              <p className=''>1</p>
                            </div>
                            <div className='col-span-2'>
                              <p className=''>www.google.com</p>
                            </div>
                          </div>

                          <div className='grid grid-cols-7 pt-2 gap-x-2'>
                            <div className='col-span-4'>
                              <p className=''>acls certification st louis</p>
                            </div>
                            <div className='col-span-1'>
                              <p className=''>1</p>
                            </div>
                            <div className='col-span-2'>
                              <p className=''>www.google.com</p>
                            </div>
                          </div>

                        </div>

                    </div>
                  </div>
                </div>

                <div className='md:px-4'>
                  <div className='rounded-lg shadow-xl'>
                    <div className='flex px-4 py-4 overflow-hidden rounded-t-lg md:px-8 bg-gradient-coustom'>
                      <div className='relative z-10 flex items-center'>
                        <div className='md:w-20 md:h-20 w-16 h-16 rounded-full bg-white md:p-4 p-2.5  flex justify-center items-center'>
                          <img src={aclsblslouisville} alt=""/>
                        </div>
                        <div className='flex-1 pl-5 text-white'>
                          <p>Project:</p>
                          <p className='text-lg font-bold'>https://acls-bls-louisville.com</p>
                        </div>
                      </div>
                    </div>
                    <div className='px-4 py-4 pb-8 bg-white rounded-b-lg md:px-8'>
                        <p>
                        CPR Louisville is a healthcare center affiliated with the American Heart Association. To create leads and assist them in achieving #1 ranking, we have given them access to Digital Marketing and Social Media Marketing.
                        </p>

                        <div className='grid grid-cols-7 pt-5 text-sm gap-x-2'>
                          <div className='col-span-4'>
                            <p className='font-bold text-primary'>Keyword</p>
                          </div>
                          <div className='col-span-1'>
                            <p className='font-bold text-primary'>Rank</p>
                          </div>
                          <div className='col-span-2'>
                            <p className='font-bold text-primary'>Search Engine</p>
                          </div>
                        </div>

                        <div className='pt-1 space-y-2 text-sm divide-y'>
                          <div className='grid grid-cols-7 pt-2 gap-x-2'>
                            <div className='col-span-4'>
                              <p className=''>bls class louisville</p>
                            </div>
                            <div className='col-span-1'>
                              <p className=''>3</p>
                            </div>
                            <div className='col-span-2'>
                              <p className=''>www.google.com</p>
                            </div>
                          </div>

                          <div className='grid grid-cols-7 pt-2 gap-x-2'>
                            <div className='col-span-4'>
                              <p className=''>acls class louisville</p>
                            </div>
                            <div className='col-span-1'>
                              <p className=''>1</p>
                            </div>
                            <div className='col-span-2'>
                              <p className=''>www.google.com</p>
                            </div>
                          </div>

                          <div className='grid grid-cols-7 pt-2 gap-x-2'>
                            <div className='col-span-4'>
                              <p className=''>pals class louisville</p>
                            </div>
                            <div className='col-span-1'>
                              <p className=''>2</p>
                            </div>
                            <div className='col-span-2'>
                              <p className=''>www.google.com</p>
                            </div>
                          </div>

                        </div>

                    </div>
                  </div>
                </div>

                <div className='md:px-4'>
                  <div className='rounded-lg shadow-xl'>
                    <div className='flex px-4 py-4 overflow-hidden rounded-t-lg md:px-8 bg-gradient-coustom'>
                      <div className='relative z-10 flex items-center'>
                        <div className='md:w-20 md:h-20 w-16 h-16 rounded-full bg-white md:p-4 p-2.5  flex justify-center items-center'>
                          <img src={cprnashvilletn} alt=""/>
                        </div>
                        <div className='flex-1 pl-5 text-white'>
                          <p>Project:</p>
                          <p className='text-lg font-bold'>http://www.cprnashvilletn.com</p>
                        </div>
                      </div>
                    </div>
                    <div className='px-4 py-4 pb-8 bg-white rounded-b-lg md:px-8'>
                        <p>
                        CPR Nashville is a local healthcare provider for students, personal trainers, lifeguards, instructors, childcare professionals, and community members in Nashville and the surrounding areas. The experts have achieved success in implementing Digital & Social Media Marketing and raise the bar with numero uno ranking spot.
                        </p>

                        <div className='grid grid-cols-7 pt-5 text-sm gap-x-2'>
                          <div className='col-span-4'>
                            <p className='font-bold text-primary'>Keyword</p>
                          </div>
                          <div className='col-span-1'>
                            <p className='font-bold text-primary'>Rank</p>
                          </div>
                          <div className='col-span-2'>
                            <p className='font-bold text-primary'>Search Engine</p>
                          </div>
                        </div>

                        <div className='pt-1 space-y-2 text-sm divide-y'>
                          <div className='grid grid-cols-7 pt-2 gap-x-2'>
                            <div className='col-span-4'>
                              <p className=''>cpr training nashville</p>
                            </div>
                            <div className='col-span-1'>
                              <p className=''>4</p>
                            </div>
                            <div className='col-span-2'>
                              <p className=''>www.google.com</p>
                            </div>
                          </div>

                          <div className='grid grid-cols-7 pt-2 gap-x-2'>
                            <div className='col-span-4'>
                              <p className=''>healthcare provider cpr nashville</p>
                            </div>
                            <div className='col-span-1'>
                              <p className=''>1</p>
                            </div>
                            <div className='col-span-2'>
                              <p className=''>www.google.com</p>
                            </div>
                          </div>

                          <div className='grid grid-cols-7 pt-2 gap-x-2'>
                            <div className='col-span-4'>
                              <p className=''>bls healthcare provider class nashville</p>
                            </div>
                            <div className='col-span-1'>
                              <p className=''>2</p>
                            </div>
                            <div className='col-span-2'>
                              <p className=''>www.google.com</p>
                            </div>
                          </div>

                        </div>

                    </div>
                  </div>
                </div>

                <div className='md:px-4'>
                  <div className='rounded-lg shadow-xl'>
                    <div className='flex px-4 py-4 overflow-hidden rounded-t-lg md:px-8 bg-gradient-coustom'>
                      <div className='relative z-10 flex items-center'>
                        <div className='md:w-20 md:h-20 w-16 h-16 rounded-full bg-white md:p-4 p-2.5  flex justify-center items-center'>
                          <img src={curbappeallawn} alt=""/>
                        </div>
                        <div className='flex-1 pl-5 text-white'>
                          <p>Project:</p>
                          <p className='text-lg font-bold'>http://www.curbappeallawn.com</p>
                        </div>
                      </div>
                    </div>
                    <div className='px-4 py-4 pb-8 bg-white rounded-b-lg md:px-8'>
                        <p>
                        Curb Appeal Lawn Care, a Simpsonville based lawn care agency has been a part of our Digital Marketing and Social Media Marketing journey. Our experts have lifted them with higher lead generation and enabling them reach top search ranking.
                        </p>

                        <div className='grid grid-cols-7 pt-5 text-sm gap-x-2'>
                          <div className='col-span-4'>
                            <p className='font-bold text-primary'>Keyword</p>
                          </div>
                          <div className='col-span-1'>
                            <p className='font-bold text-primary'>Rank</p>
                          </div>
                          <div className='col-span-2'>
                            <p className='font-bold text-primary'>Search Engine</p>
                          </div>
                        </div>

                        <div className='pt-1 space-y-2 text-sm divide-y'>
                          <div className='grid grid-cols-7 pt-2 gap-x-2'>
                            <div className='col-span-4'>
                              <p className=''>lawncare Simpsonville</p>
                            </div>
                            <div className='col-span-1'>
                              <p className=''>3</p>
                            </div>
                            <div className='col-span-2'>
                              <p className=''>www.google.com</p>
                            </div>
                          </div>

                          <div className='grid grid-cols-7 pt-2 gap-x-2'>
                            <div className='col-span-4'>
                              <p className=''>lawncare in Simpsonville</p>
                            </div>
                            <div className='col-span-1'>
                              <p className=''>5</p>
                            </div>
                            <div className='col-span-2'>
                              <p className=''>www.google.com</p>
                            </div>
                          </div>

                          <div className='grid grid-cols-7 pt-2 gap-x-2'>
                            <div className='col-span-4'>
                              <p className=''>mulch lawncare simpsonville</p>
                            </div>
                            <div className='col-span-1'>
                              <p className=''>4</p>
                            </div>
                            <div className='col-span-2'>
                              <p className=''>www.google.com</p>
                            </div>
                          </div>

                        </div>

                    </div>
                  </div>
                </div>

                <div className='md:px-4'>
                  <div className='rounded-lg shadow-xl'>
                    <div className='flex px-4 py-4 overflow-hidden rounded-t-lg md:px-8 bg-gradient-coustom'>
                      <div className='relative z-10 flex items-center'>
                        <div className='md:w-20 md:h-20 w-16 h-16 rounded-full bg-white md:p-4 p-2.5  flex justify-center items-center'>
                          <img src={aclsblskansascity} alt=""/>
                        </div>
                        <div className='flex-1 pl-5 text-white'>
                          <p>Project:</p>
                          <p className='text-lg font-bold'>http://acls-bls-kansascity.com</p>
                        </div>
                      </div>
                    </div>
                    <div className='px-4 py-4 pb-8 bg-white rounded-b-lg md:px-8'>
                        <p>
                        For students, personal trainers, lifeguards, instructors, childcare experts, and community members in Kansas and the neighborhood, CPR Kansas City is a nearby healthcare provider. We have helped them with social media marketing and digital marketing to attain more leads and reach top rankings.
                        </p>

                        <div className='grid grid-cols-7 pt-5 text-sm gap-x-2'>
                          <div className='col-span-4'>
                            <p className='font-bold text-primary'>Keyword</p>
                          </div>
                          <div className='col-span-1'>
                            <p className='font-bold text-primary'>Rank</p>
                          </div>
                          <div className='col-span-2'>
                            <p className='font-bold text-primary'>Search Engine</p>
                          </div>
                        </div>

                        <div className='pt-1 space-y-2 text-sm divide-y'>
                          <div className='grid grid-cols-7 pt-2 gap-x-2'>
                            <div className='col-span-4'>
                              <p className=''>bls for healthcare providers kansas city</p>
                            </div>
                            <div className='col-span-1'>
                              <p className=''>3</p>
                            </div>
                            <div className='col-span-2'>
                              <p className=''>www.google.com</p>
                            </div>
                          </div>

                          <div className='grid grid-cols-7 pt-2 gap-x-2'>
                            <div className='col-span-4'>
                              <p className=''>bls class kansas city</p>
                            </div>
                            <div className='col-span-1'>
                              <p className=''>2</p>
                            </div>
                            <div className='col-span-2'>
                              <p className=''>www.google.com</p>
                            </div>
                          </div>

                          <div className='grid grid-cols-7 pt-2 gap-x-2'>
                            <div className='col-span-4'>
                              <p className=''>bls for healthcare providers kansas city</p>
                            </div>
                            <div className='col-span-1'>
                              <p className=''>2</p>
                            </div>
                            <div className='col-span-2'>
                              <p className=''>www.google.com</p>
                            </div>
                          </div>

                          <div className='grid grid-cols-7 pt-2 gap-x-2'>
                            <div className='col-span-4'>
                              <p className=''>pals class kansas city</p>
                            </div>
                            <div className='col-span-1'>
                              <p className=''>1</p>
                            </div>
                            <div className='col-span-2'>
                              <p className=''>www.google.com</p>
                            </div>
                          </div>

                        </div>

                    </div>
                  </div>
                </div>
                
                <div className='md:px-4'>
                  <div className='rounded-lg shadow-xl'>
                    <div className='flex px-4 py-4 overflow-hidden rounded-t-lg md:px-8 bg-gradient-coustom'>
                      <div className='relative z-10 flex items-center'>
                        <div className='md:w-20 md:h-20 w-16 h-16 rounded-full bg-white md:p-4 p-2.5  flex justify-center items-center'>
                          <img src={anthemseniorliving} alt=""/>
                        </div>
                        <div className='flex-1 pl-5 text-white'>
                          <p>Project:</p>
                          <p className='text-lg font-bold'>http://anthemseniorliving.com</p>
                        </div>
                      </div>
                    </div>
                    <div className='px-4 py-4 pb-8 bg-white rounded-b-lg md:px-8'>
                        <p>
                        For generating leads through Social Media Marketing and Digital Marketing, we have applied tactics to achieve #1 ranking slot for Anthem Senior, an award-winning assisted living facility in the Phoenix region of Arizona.
                        </p>

                        <div className='grid grid-cols-7 pt-5 text-sm gap-x-2'>
                          <div className='col-span-4'>
                            <p className='font-bold text-primary'>Keyword</p>
                          </div>
                          <div className='col-span-1'>
                            <p className='font-bold text-primary'>Rank</p>
                          </div>
                          <div className='col-span-2'>
                            <p className='font-bold text-primary'>Search Engine</p>
                          </div>
                        </div>

                        <div className='pt-1 space-y-2 text-sm divide-y'>
                          <div className='grid grid-cols-7 pt-2 gap-x-2'>
                            <div className='col-span-4'>
                              <p className=''>anthem senior living</p>
                            </div>
                            <div className='col-span-1'>
                              <p className=''>1</p>
                            </div>
                            <div className='col-span-2'>
                              <p className=''>www.google.com</p>
                            </div>
                          </div>

                          <div className='grid grid-cols-7 pt-2 gap-x-2'>
                            <div className='col-span-4'>
                              <p className=''>Anthem assisted living</p>
                            </div>
                            <div className='col-span-1'>
                              <p className=''>3</p>
                            </div>
                            <div className='col-span-2'>
                              <p className=''>www.google.com</p>
                            </div>
                          </div>

                          <div className='grid grid-cols-7 pt-2 gap-x-2'>
                            <div className='col-span-4'>
                              <p className=''>Senior living in anthem</p>
                            </div>
                            <div className='col-span-1'>
                              <p className=''>5</p>
                            </div>
                            <div className='col-span-2'>
                              <p className=''>www.google.com</p>
                            </div>
                          </div>

                        </div>

                    </div>
                  </div>
                </div>

                <div className='md:px-4'>
                  <div className='rounded-lg shadow-xl'>
                    <div className='flex px-4 py-4 overflow-hidden rounded-t-lg md:px-8 bg-gradient-coustom'>
                      <div className='relative z-10 flex items-center'>
                        <div className='md:w-20 md:h-20 w-16 h-16 rounded-full bg-white md:p-4 p-2.5  flex justify-center items-center'>
                          <img src={cprtampa} alt=""/>
                        </div>
                        <div className='flex-1 pl-5 text-white'>
                          <p>Project:</p>
                          <p className='text-lg font-bold'>https://cprtampa.training</p>
                        </div>
                      </div>
                    </div>
                    <div className='px-4 py-4 pb-8 bg-white rounded-b-lg md:px-8'>
                        <p>
                        CPR Tampa, an American Heart Association approved healthcare organization in Florida has reached top rankings with our Digital Marketing & Social Media Marketing strategies. This has enabled them in lead generation and better customer retention.
                        </p>

                        <div className='grid grid-cols-7 pt-5 text-sm gap-x-2'>
                          <div className='col-span-4'>
                            <p className='font-bold text-primary'>Keyword</p>
                          </div>
                          <div className='col-span-1'>
                            <p className='font-bold text-primary'>Rank</p>
                          </div>
                          <div className='col-span-2'>
                            <p className='font-bold text-primary'>Search Engine</p>
                          </div>
                        </div>

                        <div className='pt-1 space-y-2 text-sm divide-y'>
                          <div className='grid grid-cols-7 pt-2 gap-x-2'>
                            <div className='col-span-4'>
                              <p className=''>acls class tampa</p>
                            </div>
                            <div className='col-span-1'>
                              <p className=''>3</p>
                            </div>
                            <div className='col-span-2'>
                              <p className=''>www.google.com</p>
                            </div>
                          </div>

                          <div className='grid grid-cols-7 pt-2 gap-x-2'>
                            <div className='col-span-4'>
                              <p className=''>pals class tampa</p>
                            </div>
                            <div className='col-span-1'>
                              <p className=''>1</p>
                            </div>
                            <div className='col-span-2'>
                              <p className=''>www.google.com</p>
                            </div>
                          </div>

                          <div className='grid grid-cols-7 pt-2 gap-x-2'>
                            <div className='col-span-4'>
                              <p className=''>basic life support tampa</p>
                            </div>
                            <div className='col-span-1'>
                              <p className=''>1</p>
                            </div>
                            <div className='col-span-2'>
                              <p className=''>www.google.com</p>
                            </div>
                          </div>

                        </div>

                    </div>
                  </div>
                </div>
                  
                </Slider>
              </div>
          </div>
      </section>

      <section className='py-16 bg-secondary-50/20 lg:py-24'>
        <div className='container'>
          <div className='max-w-4xl mx-auto text-center'>
            <h2 className='text-4xl font-bold leading-snug text-black lg:text-5xl lg:px-20'>
              Embrace Cognizance With Priceless Offerings
            </h2>
            <p className='pt-5'>
              Every phase of your client lifecycle is covered by our methods for creating value. 
              You may be proud of the outcomes since each team member takes ownership of the services 
              they render. Check below the services to know more!
            </p>
          </div>

          <div className='pt-5'>
            <div className='grid gap-12 pt-12 pr-10 lg:grid-cols-3 md:grid-cols-2'>
              <div className='flex '>
                <div className='flex items-center justify-center w-16 h-16 p-4 bg-white rounded-full shadow-lg lg:w-20 lg:h-20 lg:p-5'>
                  <img src={ServicesOne} alt=''/>
                </div>
                <div className='flex-1 pl-5'>
                  <p className='text-lg font-bold '>
                    SEO Services 
                  </p>
                  <p className='pt-2 text-sm'>
                  It aids in generating organic search traffic for your website.
                  </p>
                </div>
              </div>

              <div className='flex '>
                <div className='flex items-center justify-center w-16 h-16 p-4 bg-white rounded-full shadow-lg lg:w-20 lg:h-20 lg:p-5'>
                  <img src={ServicesTwo} alt=''/>
                </div>
                <div className='flex-1 pl-5'>
                  <p className='text-lg font-bold '>
                    SMO Services 
                  </p>
                  <p className='pt-2 text-sm'>
                    Facilitates better customer engagement and communication.
                  </p>
                </div>
              </div>

              <div className='flex '>
                <div className='flex items-center justify-center w-16 h-16 p-4 bg-white rounded-full shadow-lg lg:w-20 lg:h-20 lg:p-5'>
                  <img src={ServicesThree} alt=''/>
                </div>
                <div className='flex-1 pl-5'>
                  <p className='text-lg font-bold '>
                    PPC Services 
                  </p>
                  <p className='pt-2 text-sm'>
                    Aids in cost-effective advertising management.
                  </p>
                </div>
              </div>

              <div className='flex '>
                <div className='flex items-center justify-center w-16 h-16 p-4 bg-white rounded-full shadow-lg lg:w-20 lg:h-20 lg:p-5'>
                  <img src={ServicesFour} alt=''/>
                </div>
                <div className='flex-1 pl-5'>
                  <p className='text-lg font-bold '>
                    SMM Services
                  </p>
                  <p className='pt-2 text-sm'>
                  Facilitates the promotion of your goods and services.
                  </p>
                </div>
              </div>

              <div className='flex '>
                <div className='flex items-center justify-center w-16 h-16 p-4 bg-white rounded-full shadow-lg lg:w-20 lg:h-20 lg:p-5'>
                  <img src={ServicesFive} alt=''/>
                </div>
                <div className='flex-1 pl-5'>
                  <p className='text-lg font-bold '>
                    ORM Services
                  </p>
                  <p className='pt-2 text-sm'>
                  Helps to remove false information, and spread optimism.
                  </p>
                </div>
              </div>

              <div className='flex '>
                <div className='flex items-center justify-center w-16 h-16 p-4 bg-white rounded-full shadow-lg lg:w-20 lg:h-20 lg:p-5'>
                  <img src={ServicesSix} alt=''/>
                </div>
                <div className='flex-1 pl-5'>
                  <p className='text-lg font-bold '>
                    Content Writing 
                  </p>
                  <p className='pt-2 text-sm'>
                  Helping to raise the brand value of the business.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='py-4 bg-gradient-coustom'>
        <div className='container relative z-10'>
            <div className='grid items-center lg:grid-cols-5 lg:gap-x-10'>
              <div className='flex items-center lg:col-span-2'>
                <div className='w-24 h-24 p-2 rounded-full bg-white/30'>
                  <div className='w-full h-full rounded-full '>
                      <img src={userImage} className='object-cover' alt=''/>
                  </div>
                </div>
                <div className='flex-1 pl-5 text-white'>
                  <p className='pb-2'>Ready to speak with a marketing expert?</p>
                  <a href='mailto:info@rankwebz.com' className='text-3xl font-bold lg:text-4xl'>info@rankwebz.com</a>
                </div>
              </div>
              <div className='flex items-center pt-5 space-x-3 divide-x lg:col-span-3 lg:pt-0 sm:space-x-7 lg:justify-end divide-white/20'>
                <div className='text-white '>
                  <p className='text-xl font-bold sm:text-3xl'>1.2 million</p>
                  <p className='pt-2 text-sm sm:text-base text-white/80'>Hours of Expertise</p>
                </div>
                <div className='pl-3 text-white sm:pl-7'>
                  <p className='text-xl font-bold sm:text-3xl'>150+</p>
                  <p className='pt-2 text-sm sm:text-base text-white/80'>Digital Marketing Masters</p>
                </div>

                <div className='pl-3 text-white sm:pl-7'>
                  <p className='text-xl font-bold sm:text-3xl'>900</p>
                  <p className='pt-2 text-sm sm:text-base text-white/80'>Success Projects</p>
                </div>
              </div>
            </div>
        </div>
      </section>

      <section className='py-16 bg-black lg:py-20 '>
        <div className='container relative z-10'>
          <div className='grid lg:grid-cols-2'>
            <div className='col-span-1'>
              <h2 className='text-3xl font-bold leading-relaxed text-white lg:text-5xl md:text-4xl lg:pr-10'>
                Navigate The <br/>Digital Marketing Frequently <br/> Asked Questions <br/>With 
                <span className='inline-block px-5 ml-4 bg-gradient-coustom'>RankWebz</span>
              </h2>
             
            </div>
            <div className='space-y-5 pt-7 lg:pt-0'>
             
              <Disclosure as="div" className="mt-2">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="relative flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-white transition-all bg-transparent bg-black border rounded-md hover:text-primary focus:outline-none border-white/50">
                      <span className='pr-16 text-base font-bold'>What exactly is digital marketing, and why is it crucial for my company?</span>
                      <span className='absolute top-0 bottom-0 right-0 flex items-center justify-center w-12 font-bold text-white border rounded-r-md bg-primary border-primary'>
                        {open ? (
                          <TiMinus size={22}/>
                        ):(
                          <GoPlus size={22}/>
                        )}
                        
                      </span>
                    </Disclosure.Button>
                    <Transition
                      show={open}
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Disclosure.Panel className="px-4 pt-4 pb-3 border-t-0 rounded-b-md -mt-0.5 text-sm text-white border border-white/50">
                        Digital marketing is the process of promoting or advertising your company, service, or product online or over the Internet. 
                        The efforts may differ based on the type of business, the goals, or the stage of the business lifecycle. 
                        To be able to pinpoint the most lucrative channels, it's critical to collaborate with a marketing team that comprehends 
                        your objectives and knows how to develop a thorough strategy.
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>

              <Disclosure as="div" className="mt-2">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="relative flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-white transition-all bg-transparent bg-black border rounded-md hover:text-primary focus:outline-none border-white/50">
                      <span className='pr-16 text-base font-bold'>What type of digital campaigns do you run?</span>
                      
                      <span className='absolute top-0 bottom-0 right-0 flex items-center justify-center w-12 font-bold text-white border rounded-r-md bg-primary border-primary'>
                        {open ? (
                          <TiMinus size={22}/>
                        ):(
                          <GoPlus size={22}/>
                        )}
                        
                      </span>
                    </Disclosure.Button>
                    <Transition
                      show={open}
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Disclosure.Panel className="px-4 pt-4 pb-3 border-t-0 rounded-b-md -mt-0.5 text-sm text-white border border-white/50">
                        Although there are many various sorts of marketing methods used in digital marketing, the range of services we offer often includes website design and development, search engine optimization, pay-per-click advertising, social media marketing, content marketing, and email marketing. There are divisions of the various sorts of digital marketing even within the aforementioned tactics.
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>

              <Disclosure as="div" className="mt-2">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="relative flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-white transition-all bg-transparent bg-black border rounded-md hover:text-primary focus:outline-none border-white/50">
                      <span className='pr-16 text-base font-bold'>How much cost do I need to bear?</span>
                      <span className='absolute top-0 bottom-0 right-0 flex items-center justify-center w-12 font-bold text-white border rounded-r-md bg-primary border-primary'>
                        {open ? (
                          <TiMinus size={22}/>
                        ):(
                          <GoPlus size={22}/>
                        )}
                        
                      </span>
                    </Disclosure.Button>
                    <Transition
                      show={open}
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Disclosure.Panel className="px-4 pt-4 pb-3 border-t-0 rounded-b-md -mt-0.5 text-sm text-white border border-white/50">
                        Be rest assured of the services that we will provide to propel your business growth. Our experts will ensure that you have a higher return in comparison to the amount you invested in. You may find several other service providers; however, we will make sure that you receive the best cost-effective services. 
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>

              <Disclosure as="div" className="mt-2">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="relative flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-white transition-all bg-transparent bg-black border rounded-md hover:text-primary focus:outline-none border-white/50">
                      <span className='pr-16 text-base font-bold'>How can I calculate the appropriate budget for the digital marketing initiatives of my business?</span>
                    
                      <span className='absolute top-0 bottom-0 right-0 flex items-center justify-center w-12 font-bold text-white border rounded-r-md bg-primary border-primary'>
                        {open ? (
                          <TiMinus size={22}/>
                        ):(
                          <GoPlus size={22}/>
                        )}
                        
                      </span>
                    </Disclosure.Button>
                    <Transition
                      show={open}
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Disclosure.Panel className="px-4 pt-4 pb-3 border-t-0 rounded-b-md -mt-0.5 text-sm text-white border border-white/50">
                        Identifying the return on investment of any marketing endeavor is one of the most crucial numbers.
                        <br/> <br/>
                        To fully grasp the possibilities that our team can offer your company, our team will assist you in calculating the genuine return on investment of your marketing expenditure. We are performance-driven, thus we make it a point to comprehend the lifetime worth of your clients and/or consumers. This will assist you to comprehend the potential multiples from a marketing approach. 

                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>

              <Disclosure as="div" className="mt-2">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="relative flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-white transition-all bg-transparent bg-black border rounded-md hover:text-primary focus:outline-none border-white/50">
                      <span className='pr-16 text-base font-bold'>Why should I commission RankWebz to carry out digital marketing tasks for my company?</span>
                     
                      <span className='absolute top-0 bottom-0 right-0 flex items-center justify-center w-12 font-bold text-white border rounded-r-md bg-primary border-primary'>
                        {open ? (
                          <TiMinus size={22}/>
                        ):(
                          <GoPlus size={22}/>
                        )}
                        
                      </span>
                    </Disclosure.Button>
                    <Transition
                      show={open}
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Disclosure.Panel className="px-4 pt-4 pb-3 border-t-0 rounded-b-md -mt-0.5 text-sm text-white border border-white/50">
                        Performance marketing and advertising strategy are the main priorities of RankWebz. Our objective is to significantly increase the Return on Investment (ROI) for your company while encouraging you to keep spending money on marketing services as a result of the effectiveness of our solutions.
                        <br/> <br/>
                        We pledge to do everything in our power to add a strong, efficient marketing staff that is driven to make your business successful. This is our commitment to you.

                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>

              <Disclosure as="div" className="mt-2">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="relative flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-white transition-all bg-transparent bg-black border rounded-md hover:text-primary focus:outline-none border-white/50">
                      <span className='pr-16 text-base font-bold'>What kind of assistance do you offer my firm regarding your goods or services?</span>

                      <span className='absolute top-0 bottom-0 right-0 flex items-center justify-center w-12 font-bold text-white border rounded-r-md bg-primary border-primary'>
                        {open ? (
                          <TiMinus size={22}/>
                        ):(
                          <GoPlus size={22}/>
                        )}
                        
                      </span>
                    </Disclosure.Button>
                    <Transition
                      show={open}
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Disclosure.Panel className="px-4 pt-4 pb-3 border-t-0 rounded-b-md -mt-0.5 text-sm text-white border border-white/50">
                        Our incredible team offers individualized support for all of our goods and services. We take great pride in offering the greatest customer service imaginable and making sure that we are accessible to you at all times for support.
                        <br/><br/>
                        Imagine having access to an executive marketing dashboard that provides insights into your marketing strategy around the clock.

                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            </div>
          </div>
        </div>
      </section>

      <section className='py-12 bg-gradient-coustom lg:py-20'>
        <div className='container relative z-10'>
          <div className='max-w-4xl mx-auto text-center'>
            <h1 className='text-3xl font-bold leading-snug text-white lg:text-5xl'>
              Are you prepared for us? Create your winning strategy right away.
            </h1>
           

            <div className='relative max-w-lg mx-auto mt-10'>
              <form onSubmit={formikMob.handleSubmit}>
                <input type='text' 
                  name='phonenumber'
                  maxLength={'15'}
                  onChange={formikMob.handleChange}
                  value={NumberConvart(formikMob.values.phonenumber)}
                  onBlur={formikMob.handleBlur}
                  className='w-full py-3 pl-5 text-sm bg-white border border-gray-500 outline-none pr-44 rounded-3xl focus:border-primary' placeholder='Enter your phone number' /> 
                  <div className='absolute top-0 bottom-0 right-0'>
                    <button disabled={isSubmit} className='w-40 h-full text-white bg-black btn md:w-52'>Book Intro Call</button>
                  </div>
              </form>
            </div>

            {formikMob.errors.phonenumber && formikMob.touched.phonenumber && (
              <p className='mt-1 text-sm text-red-400'>
                {formikMob.errors.phonenumber}
              </p>
            )}
            {mobThankYou && (
              <div className='px-5 py-2 text-base font-bold text-center text-green-500 rounded col-span-full'>
                Thank You For Getting In Touch With RankWebz
              </div>
            )}

            {mobError && (
              <div className='px-5 py-2 text-base text-center text-red-500 rounded col-span-full'>
                Network Error
              </div>
            )}

          </div>
        </div>
      </section>

      <section className='pt-24 pb-10 lg:pb-24'>
        <div className='container'>
          <div className='max-w-3xl mx-auto text-center'>
            <h2 className='text-3xl font-bold leading-snug text-black lg:text-5xl md:text-4xl lg:px-20'>
              How Do We Operate?
            </h2>
            <p className='pt-5'>
              We tie each component of the marketing framework to your consumer base in 
              an effort to have a long-lasting effect on your company.
            </p>
          </div>

          <div className='mt-16'>
            <div className='grid bg-white rounded-lg shadow-lg lg:grid-cols-3 lg:gap-10 shadow-gray-300'>
              <div>
                  <img className='object-cover w-full h-40 rounded-t-lg lg:h-full lg:rounded-l-lg' src={OperateImage} alt=""/>
              </div>
              <div className='px-5 py-12 lg:col-span-2 lg:pr-10'>
                <div className='grid gap-8 lg:grid-cols-2 lg:gap-14'>
                  <div className='flex'>
                    <div className='flex items-center justify-center w-10 h-10 p-2 rounded-md shadow-lg md:w-14 md:p-3 md:h-14 bg-primary'>
                      <img src={OperateIconOne} alt=''/>
                    </div>
                    <div className='flex-1 pl-5'>
                      <p className='text-lg font-bold '>
                        Research
                      </p>
                      <p className='pt-2 text-sm'>
                        Our object in this phase is to understand the current line of operations 
                        and to find out the growth hindrances.
                      </p>
                    </div>
                  </div>

                  <div className='flex '>
                    <div className='flex items-center justify-center w-10 h-10 p-2 rounded-md shadow-lg md:w-14 md:p-3 md:h-14 bg-primary'>
                      <img src={OperateIconTwo} alt=''/>
                    </div>
                    <div className='flex-1 pl-5'>
                      <p className='text-lg font-bold '>
                        Analyze
                      </p>
                      <p className='pt-2 text-sm'>
                      In this step, a complete study of the bottleneck is done to find 
                      out the cause and the effect relationship.
                      </p>
                    </div>
                  </div>

                  <div className='flex '>
                    <div className='flex items-center justify-center w-10 h-10 p-2 rounded-md shadow-lg md:w-14 md:p-3 md:h-14 bg-primary'>
                      <img src={OperateIconThree} alt=''/>
                    </div>
                    <div className='flex-1 pl-5'>
                      <p className='text-lg font-bold '>
                        Planning
                      </p>
                      <p className='pt-2 text-sm'>
                        The third phase consists of creating a blueprint for the future course of action.
                      </p>
                    </div>
                  </div>

                  <div className='flex '>
                    <div className='flex items-center justify-center w-10 h-10 p-2 rounded-md shadow-lg md:w-14 md:p-3 md:h-14 bg-primary'>
                      <img src={OperateIconFour} alt=''/>
                    </div>
                    <div className='flex-1 pl-5'>
                      <p className='text-lg font-bold '>
                        Optimize
                      </p>
                      <p className='pt-2 text-sm'>
                        Here, the best effort is exerted to look for the optimal 
                        performance in growth metrics.
                      </p>
                    </div>
                  </div>

                  <div className='flex '>
                    <div className='flex items-center justify-center w-10 h-10 p-2 rounded-md shadow-lg md:w-14 md:p-3 md:h-14 bg-primary'>
                      <img src={OperateIconFive} alt=''/>
                    </div>
                    <div className='flex-1 pl-5'>
                      <p className='text-lg font-bold '>
                        Promote
                      </p>
                      <p className='pt-2 text-sm'>
                        Promotion plays a big role in marketing as it helps to a wider traffic generation.
                      </p>
                    </div>
                  </div>

                  <div className='flex '>
                    <div className='flex items-center justify-center w-10 h-10 p-2 rounded-md shadow-lg md:w-14 md:p-3 md:h-14 bg-primary'>
                      <img src={OperateIconSix} alt=''/>
                    </div>
                    <div className='flex-1 pl-5'>
                      <p className='text-lg font-bold '>
                        Report
                      </p>
                      <p className='pt-2 text-sm'>
                        A continuous monitoring is done and the report is extracted to 
                        mitigate in case of any deviation.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </section>

      <section className='relative mt-10 before:absolute before:left-0 before:right-0 before:bottom-0 before:h-1/2 before:bg-secondary-50/20'>
        <div className='container relative z-10'>
          <div className='px-2 py-5 overflow-hidden rounded-lg bg-gradient-coustom lg:py-12 lg:px-10'>
            <div className='relative z-10 text-center text-white'>
              <h2 className='max-w-4xl mx-auto text-2xl font-bold lg:text-5xl md:text-4xl sm:text-3xl'>
                We Make Your Prospect Experience Come To Life Online!
              </h2>
              <p className='pt-5 text-base'>
                Join forces with a team that increases your revenue by generating leads using tested, analytics-based tactics!
              </p>

              <div className='flex flex-col items-center justify-center pt-10 space-y-2 lg:flex-row lg:space-y-0 lg:space-x-4'>
                <button onClick={() => HandelWebAnalysModal('Get Free Consultations')} className='bg-black shadow-sm btn hover:bg-primary hover:text-white'>
                  Get Free Consultations
                </button>
                <p className='text-sm font-bold'>OR</p>
                <button onClick={() => {HandelWebAnalysModal('Schedule a discovery'); setIsDate(true)}} className='font-bold text-black bg-white shadow-sm btn hover:bg-primary hover:text-white'>
                  Schedule A Discovery
                </button>
                {/* <a href='tel:+91 9830445152' className='font-bold text-black bg-white shadow-sm btn hover:bg-primary hover:text-white'>
                  Dial +91 9830445152
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='bg-secondary-50/20 lg:py-20 py-14'>
        <div className='container'>
          <div className='mx-auto text-center'>
            <h2 className='text-2xl font-bold leading-tight text-black lg:text-5xl md:text-4xl sm:text-3xl lg:px-20'>
              RankWebz Is The Ideal Option If You're Searching For The Top Digital 
              Marketing Tactics For Your Company
            </h2>
           
          </div>

          <div className='max-w-5xl mx-auto lg:pt-5'>
            <div className='grid pt-5 divide-gray-300 lg:grid-cols-2 lg:gap-12 lg:pt-12 lg:divide-x'>
              <div>
                <ul className="pt-10 pb-8 space-y-4 text-black list-items">
                  <li>360 degrees of tracking & reporting</li>
                  <li>Years of experience</li>
                  <li>Industry professionals with a focus on strategy</li>
                  <li>Leveraging growth through higher sales</li>
                </ul>
              </div>

              <div className='lg:pl-16'>
                <ul className="pb-3 space-y-4 text-black list-items lg:pt-10 lg:pb-8">
                  <li>Boosting clients’ ROI</li>
                  <li>Higher organic growth in traffic generation</li>
                  <li>Helping in Brand Promotion</li>
                  <li>Sustainable development</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='pt-16 pb-8 lg:pt-32'>
        <div className='container'>
          <div className='rounded-lg shadow-lg shadow-slate-300'>
            <div className='h-3 overflow-hidden rounded-t-lg bg-gradient-coustom'></div>
            <div className='bg-white rounded-b-lg'>
              <p className='pt-5 font-bold text-center lg:pt-10'>Featured In</p>
              <div className='max-w-4xl px-5 pt-5 mx-auto marketersSlider lg:pt-10 lg:pb-16 pb-7 lg:px-0'>
                <Slider {...marketersSlider}>
                  {featuredLogo && featuredLogo.length > 0 && featuredLogo.map((items, i) => {
                    return(
                      <div key={i} className='px-4 text-center'>
                        <div className='flex items-center justify-center w-full h-20'>
                          <img className='object-cover h-auto' src={items} alt=''/>
                        </div>
                      </div>
                    )
                  })}
                </Slider>
              </div>
              <div className='' id='componentToScrollTo'></div>
            </div>
          </div>
        </div>
      </section>
      
      <section className='relative lg:py-24 py-14 lg:mt-16 before:absolute before:top-0 before:left-0 before:bottom-0 lg:before:w-1/2 before:w-full before:bg-black'>
        <div className='absolute lg:top-0 top-[46%] right-0 bottom-0 lg:w-1/2 w-full '>
          <div className='w-full h-full bg-gradient-coustom'></div>
        </div>
        <div className='container relative z-10'>
          <div className='grid items-center lg:grid-cols-2'>
              <div className='text-white bg-black'>
                  <h4 className='text-3xl font-bold leading-snug lg:text-5xl md:text-4xl'>Ready To Explore <br/> With Us?</h4>
                  <p className='pt-2 font-bold'>Let’s Connect</p>

                  <div className='pt-10 space-y-8 lg:pt-16'>
                    <div className='flex items-center'>
                      <div className='flex items-center justify-center w-12 h-12 rounded-md lg:w-16 lg:h-16 bg-primary'>
                          <HiOutlineMail size={35}/>
                      </div>
                      <div className='flex-1 pl-5'>
                        <p className='text-base text-white/80'>Contact Us By Mailing</p>
                        <p className='text-xl font-bold'>info@rankwebz.com</p>
                      </div>
                    </div>

                    <div className='flex items-center'>
                      <div className='flex items-center justify-center w-12 h-12 rounded-md lg:w-16 lg:h-16 bg-primary'>
                          <HiOutlineMail size={35}/>
                      </div>
                      <div className='flex-1 pl-5'>
                        <p className='text-base text-white/80'>Contact Us By Mailing</p>
                        <p className='text-xl font-bold'>mike@rankwebz.com</p>
                      </div>
                    </div>

                    <div className='flex items-center'>
                      <div className='flex items-center justify-center w-12 h-12 rounded-md lg:w-16 lg:h-16 bg-primary'>
                          <FaSkype size={35}/>
                      </div>
                      <div className='flex-1 pl-5'>
                        <p className='text-base text-white/80'>Our Skype ID</p>
                        <p className='text-xl font-bold'>live:info_157119</p>
                      </div>
                    </div>

                    {/* <div className='flex items-center'>
                      <div className='flex items-center justify-center w-12 h-12 rounded-md lg:w-16 lg:h-16 bg-primary'>
                          <BsWhatsapp size={30}/>
                      </div>
                      <div className='flex-1 pl-5'>
                        <p className='text-base text-white/80'>Call Now</p>
                        <p className='flex flex-col pt-1'>
                          <a href='tel:+91 9830445152' className='text-xl font-bold leading-5 inline-block'>+91 9830445152</a>
                        </p>
                      </div>
                    </div> */}

                  </div>
              </div>

              <div className=''>
                <form onSubmit={formik.handleSubmit} className='grid pt-20 sm:grid-cols-2 lg:pl-12 lg:pt-0 sm:gap-y-6 gap-y-4 gap-x-4'>
                  <div>
                    <input type={'text'} 
                      className="form-control" 
                      name='name'
                      placeholder="Name*" 
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.name && formik.touched.name && (
                      <p className='mt-0.5 text-xs text-white'>
                        {formik.errors.name}
                      </p>
                    )}
                  </div>

                  <div>
                    <input type={'email'} className="form-control" placeholder="Email Address*"  name='email'
                      onChange={formik.handleChange}
                     value={formik.values.email}
                     onBlur={formik.handleBlur}
                    />
                    {formik.errors.email && formik.touched.email && (
                        <p className='mt-0.5 text-xs text-white'>
                          {formik.errors.email}
                        </p>
                    )}
                  </div>

                  <div>
                    <input type={'text'} className="form-control" placeholder="Phone No" 
                      name='phone'
                      maxLength={'15'}
                      onChange={formik.handleChange}
                      value={NumberConvart(formik.values.phone)}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.phone && formik.touched.phone && (
                      <p className='mt-0.5 text-xs text-white'>
                        {formik.errors.phone}
                      </p>
                    )}
                  </div>

                  <div>
                    <select className="form-control" name='interest'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                     value={formik.values.interest}>
                      <option value={''}>Interested In</option>
                      <option value={'Hire Digital Marketing Specialist'}>Hire Digital Marketing Specialist</option>
                      <option value={'Digital Marketing Services'}>Digital Marketing Services</option>
                      <option value={'SEO Services'}>SEO Services</option>
                      <option value={'Web Design Services'}>Web Design Services</option>
                      <option value={'Social Media Services'}>Social Media Services</option>
                      <option value={'Digital Advertising Services'}>Digital Advertising Services</option>
                      <option value={'Content Marketing Services'}>Content Marketing Services</option>
                      <option value={'PPC'}>PPC</option>
                    </select>
                    {formik.errors.interest && formik.touched.interest && (
                        <p className='mt-0.5 text-xs text-white'>
                          {formik.errors.interest}
                        </p>
                      )}
                  </div>

                  <div>
                    <input type={'text'} className="form-control" placeholder="Web Address*" name='web'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                     value={formik.values.web}
                    />
                    {formik.errors.web && formik.touched.web && (
                      <p className='mt-0.5 text-xs text-white'>
                        {formik.errors.web}
                      </p>
                    )}
                  </div>

                  <div>
                    <input type={'text'} className="form-control" placeholder="Skype ID" name='skype'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                     value={formik.values.skype}
                    />
                    {formik.errors.skype && formik.touched.skype && (
                      <p className='mt-0.5 text-xs text-white'>
                        {formik.errors.skype}
                      </p>
                    )}
                  </div>

                  <div className='col-span-full'>
                    <textarea className="form-control" rows="4" placeholder="Message*" name='message'
                      onChange={formik.handleChange}
                      value={formik.values.message}
                      onBlur={formik.handleBlur}
                    >

                    </textarea>
                      {formik.errors.message && formik.touched.message && (
                        <p className='mt-0.5 text-xs text-white'>
                          {formik.errors.message}
                        </p>
                      )}
                  </div>

                  {thankYou && (
                    <div className='px-5 py-2 text-base font-bold text-center text-green-500 bg-white rounded col-span-full'>
                      Thank You For Getting In Touch With RankWebz
                    </div>  
                  )}

                  {error && (
                    <div className='px-5 py-2 text-base font-bold text-center text-red-500 bg-white rounded col-span-full'>
                    Network Error
                    </div>
                  )}

                  <div className='col-span-full'>
                    <button type='submit' disabled={isSubmit} className='h-12 mt-3 text-center text-white bg-black shadow-sm btn sm:mt-8 hover:bg-primary hover:text-white'>
                     
                      <span className='inline-flex'>
                          <span className='flex-1 items-center'>
                              Send Your Enquiry
                          </span>
                          {btnLoader && (
                              <span className='animate-spin h-5 w-5 ml-3 inline-block border-white rounded-full border-b-0 border-2'></span>
                          )}
                      </span>
                    </button>
                  </div>
                </form>
              </div>
          </div>
        </div>
      </section>

      <ContactMoadal isDate={isDate} modalOpen={websiteAnalysisModal} titleText={modalTitle} HandelCloseModal={HandelCloseModal}/>
    </Layout>
  )
}


