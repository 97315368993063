import React from 'react'
import { Layout } from '../../Components'

const ThankYou = () => {
  return (
    <Layout>
        <section  className='relative z-10 py-10'>
        <div className='container text-center'>
          <div className='max-w-3xl mx-auto md:pt-10'>
            <h1 className='text-3xl pb-2 font-bold md:text-5xl sm:text-4xl text-gradient'>
              Thanking You For Submission
            </h1>
            <p className='pt-3 text-sm md:text-lg md:pt-5'>
              We appreciate you taking valuable time and getting in touch with us. One of our digital experts will get back to you shortly!
            </p>

            <div className='relative max-w-lg mx-auto mt-7 md:mt-10'>
              
            </div>
            
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ThankYou
