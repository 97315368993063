import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { americanFlag, FullLogo } from '../../Assets';
import {FaFacebookF, FaBloggerB} from 'react-icons/fa';
import {AiOutlineTwitter, AiFillInstagram, AiFillLinkedin} from 'react-icons/ai';

import axios from 'axios';
import { useFormik } from 'formik';
import { useState } from 'react';
import validator from 'validator';


export default function Footer({HandelWebAnalysModal}) {
    const [thankYou, setThankYou] = useState(false);
    const [error, setError] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [btnLoader, setBtnLoader] = useState(false);

    const navigate = useNavigate();
    


    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validate: (values) => {
            const errors = {};

            if (!values.email) {
                errors.email = 'Please enter email address!';
            } else if (!validator.isEmail(values.email)) {
                errors.email = 'Invalid email address';
            }

            return errors;
        },
        onSubmit: (values, { resetForm }) => {
        setIsSubmit(true);
        setBtnLoader(true);
        // https://works-reddensoft.com/reddensite/api/subscribemail 
        axios.post('https://rankwebz.com/admin/api/storenewsletter', values)
            .then((res) => {
                setThankYou(true);
                resetForm();
                navigate('/thank-you');
            })
            .catch((errors) => {
                setError(true);
            })
            .finally(() => {
                setIsSubmit(false);
                setBtnLoader(false);
                setTimeout(() => {
                ErrorRemove()
                }, 3000);
            })

        }
    });

    const ErrorRemove = () => {
        setThankYou(false);
        setError(false);
    }

  return (
    <>
        <footer className='md:pt-20 pt-10'>
            <div className='container'>
                <div className='grid lg:grid-cols-9 md:grid-cols-2 grid-cols-1 pb-6'>
                    <div className='pb-8 md:col-span-3'>
                        <img className='w-44' src={FullLogo} alt={'logo rank webx'} />
                        <p className='pt-5'>
                            RankWebz, a full-service digital marketing agency has been providing 
                            services for the last 7 years. Get higher conversion and more lead generation 
                            with our dedicated services.
                        </p>
                        <button onClick={() => HandelWebAnalysModal('Let’s Get Started')} className='border btn mt-5 bg-gradient-coustom text-white'>
                            <span className='relative z-10'>Let’s Get Started</span>
                        </button>

                        <ul className='flex space-x-3 pt-8 items-center'>
                        <li>
                            <a href='https://www.facebook.com/RankWebzDigital' rel="noreferrer" target="_blank" className='text-black hover:text-primary transition-all'>
                                <FaFacebookF size={20}/>
                            </a>
                        </li>
                        <li>
                            <a href='https://twitter.com/RankWebzSEO' target="_blank" className='text-black hover:text-primary transition-all'>
                                <AiOutlineTwitter size={23}/>
                            </a>
                        </li>
                        
                        <li>
                            <a href='https://www.linkedin.com/company/rankwebz' target="_blank" className='text-black hover:text-primary transition-all'>
                                <AiFillLinkedin size={22}/>
                            </a>
                        </li>
                        <li>
                            <a href='https://rankwebz.com/blog' target="_blank" className='text-black hover:text-primary transition-all'>
                                    <FaBloggerB size={18}/>
                                </a>
                            </li>
                       
                    </ul>
                    </div>

                    <div className='pb-8 lg:pl-12 lg:col-span-2'>
                        <p className='text-xl font-bold'>Services</p>
                        <ul className='space-y-1 sm:space-y-2 lg:pt-4 pt-2'>
                            <li>
                                <p className='text-btn' to={`/`}>
                                    Digital Marketing Services
                                </p>
                            </li>
                            <li>
                                <p className='text-btn' to={`/`}>
                                    SEO Services
                                </p>
                            </li>
                            <li>
                                <p className='text-btn' to={`/`}>
                                    GMB Marketing
                                </p>
                            </li>
                            <li>
                                <p className='text-btn' to={`/`}>
                                    Link Building Services 
                                </p>
                            </li>
                            <li>
                                <p className='text-btn' to={`/`}>
                                SEO Friendly Web Design
                                </p>
                            </li>
                            <li>
                                <p className='text-btn' to={`/`}>
                                    Social Media Marketing
                                </p>
                            </li>
                            <li>
                                <p className='text-btn' to={`/`}>
                                    Email Marketing
                                </p>
                            </li>
                            <li>
                                <p className='text-btn' to={`/`}>
                                    Content Marketing Services
                                </p>
                            </li>
                        </ul>
                    </div>

                    <div className='pb-8 md:pl-12 md:col-span-2'>
                        <p className='text-xl font-bold'>Our Skill</p>
                        <ul className='space-y-1 sm:space-y-2 lg:pt-4 pt-2'>
                            <li>
                                <p className='text-btn' to={`/`}>
                                    Google Adwords Campaign
                                </p>
                            </li>
                            <li>
                                <p className='text-btn' to={`/`}>
                                    SEO Agency
                                </p>
                            </li>
                            <li>
                                <p className='text-btn' to={`/`}>
                                    Facebook Campaign
                                </p>
                            </li>
                            <li>
                                <p className='text-btn' to={`/`}>
                                    Local SEO Services
                                </p>
                            </li>
                            <li>
                                <p className='text-btn' to={`/`}>
                                eCommerce Website Marketing
                                </p>
                            </li>
                            <li>
                                <p className='text-btn' to={`/`}>
                                    CPR Website Marketing
                                </p>
                            </li>
                            <li>
                                <p className='text-btn' to={`/`}>
                                    Mobile App Marketing
                                </p>
                            </li>
                        
                        </ul>
                    </div>

                    <div className='pb-8 lg:pl-12 md:col-span-2'>
                        <p className='text-xl font-bold'>Address</p>
                        {/* <div className='flex pt-3'>
                            <span className='w-7 h-7 shrink-0 pr-1 pt-1'>
                                <img className='max-w-full' src='https://cdn.pixabay.com/photo/2016/08/24/17/07/india-1617463__340.png' alt='india flag' />
                            </span>
                            <p className='text-sm'>
                                Salt Lake, Sector II, Kolkata, West Bengal 700091
                            </p>
                        </div> */}
                        <div className='flex pt-3'>
                            <span className='w-7 h-7 shrink-0 pr-1 pt-1'>
                                <img className='max-w-full' src={americanFlag} alt='american flag'/>
                            </span>
                            <p className='text-sm '>
                                7901 4th ST N STE 8022 St. Petersburg, FL 33702
                            </p>
                        </div>
                        
                        <div className='mt-5'>
                            <p className='text-sm font-bold text-slate-700'>Subscribe to our newsletter</p>
                            <form className='mt-2' onSubmit={formik.handleSubmit}>

                                <input type={'email'} name={'email'}
                                    className="text-sm px-5 py-5 h-4 w-full border" 
                                    placeholder="Enter your email address*"
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.errors.email && formik.touched.email && (
                                    <p className='mt-0.5 text-xs text-red-400'>
                                        {formik.errors.email}
                                    </p>
                                )}
                                <button type={'submit'} disabled={isSubmit} className=' w-fit block px-9 py-2 rounded-3xl font-semibold transition-all mt-2 bg-gradient-coustom text-white text-center border overflow-hidden capitalize'>
                                    <span className='inline-flex'>
                                        <span className='flex-1 items-center'>
                                            subscribe
                                        </span>
                                        {btnLoader && (
                                            <span className='animate-spin h-5 w-5 ml-3 inline-block border-white rounded-full border-b-0 border-2'></span>
                                        )}
                                    </span>
                                </button>

                                {thankYou && (
                                    <div className=' p-2 mt-1 text-sm text-center text-green-500 bg-green-50 border border-green-400 rounded col-span-full'>
                                        Thank You For Getting In Touch With RankWebz
                                    </div>
                                )}

                                {error && (
                                    <div className='px-5 py-2  mt-1 text-base font-bold text-center text-red-500 bg-white rounded col-span-full'>
                                        Network Error
                                    </div>
                                )}


                            </form>
                        </div>
                    </div> 

                </div>
            </div>

            <div className='border-t py-4'>
                <div className='container flex flex-col sm:flex-row justify-between items-center text-sm text-gray-600'>
                    <p>
                        2023 All rights reserved. RankWebz.
                    </p>
                   
                </div>
            </div>
        </footer>

        
    </>
    
  )
}
