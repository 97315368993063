
import { BrowserRouter, createBrowserRouter, createRoutesFromElements, Route, Routes } from 'react-router-dom';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './App.css';
import { HomePage, ThankYou } from './Pages';
import { MissingRoute } from './Components/Widgets/MissingRoute';


const App = () =>{
  return (
    <BrowserRouter>
      <Routes basename={'/'}>
        <Route path={`${process.env.PUBLIC_URL}/`} element={ <HomePage/> } />
        <Route path={`${process.env.PUBLIC_URL}/thank-you`} element={ <ThankYou/> } />
        <Route path="*" element={<MissingRoute/>} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
