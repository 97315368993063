import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FullLogo, LogoIcon } from '../../Assets';
import {BsWhatsapp} from 'react-icons/bs';
import {SiSkype} from 'react-icons/si';
import {FaFacebookF, FaBloggerB} from 'react-icons/fa';
import {HiOutlineMail} from 'react-icons/hi';
import {AiOutlineTwitter, AiFillLinkedin} from 'react-icons/ai';

const Header = ({active, HandelWebAnalysModal}) => {
    const location = useLocation();
    const currentPath = location.pathname.split('/');

    return (
        <>
            <div id='banner'></div>
            <header className={`sticky top-0 left-0 transition-all right-0 w-full z-30 bg-white ${active ? 'shadow-lg':''}`}>
                
                <div className='bg-black sm:p-2 p-1.5'>
                    <div className='container flex items-center justify-center sm:justify-between'>
                        <ul className='items-center hidden space-x-2  sm:flex'>
                            <li>
                                <a href='https://www.facebook.com/RankWebzDigital' target="_blank" className='text-white transition-all hover:text-primary'>
                                    <FaFacebookF size={14}/>
                                </a>
                            </li>
                            <li>
                                <a href='https://twitter.com/RankWebzSEO' target="_blank" className='text-white transition-all hover:text-primary'>
                                    <AiOutlineTwitter size={19}/>
                                </a>
                            </li>
                            <li>
                                <a href='https://www.linkedin.com/company/rankwebz' target="_blank" className='text-white transition-all hover:text-primary'>
                                    <AiFillLinkedin size={18}/>
                                </a>
                            </li>
                            <li>
                                <a href='https://rankwebz.com/blog' target="_blank" className='text-white transition-all hover:text-primary'>
                                    <FaBloggerB size={18}/>
                                </a>
                            </li>
                           
                        </ul>

                        <p className='hidden text-sm text-white lg:block'>
                            Harvest growth with 360° Digital Marketing agency
                        </p>

                        <ul className='flex items-center space-x-3 divide-x divide-gray-500'>
                            <li className='flex'>
                                <Link title='live:info_157119' className='inline-flex items-center text-xs text-white transition-all sm:font-bold sm:text-sm hover:text-primary'>
                                    <span title='live:info_157119' className='hidden pr-2 text-primary sm:block'><SiSkype size={18}/></span>
                                    <span title='live:info_157119' className='block pr-2 text-primary sm:hidden'><SiSkype size={16}/></span>
                                    <span title='live:info_157119'>Rankwebz</span>
                                </Link>
                            </li>

                            <li className='flex pl-3'>
                                <a href='mailto:info@rankwebz.com' className='inline-flex items-center text-xs text-white transition-all sm:font-bold sm:text-sm hover:text-primary'>
                                    <span className='hidden pr-2 text-primary sm:block'><HiOutlineMail size={18}/></span>
                                    <span className='block pr-2 text-primary sm:hidden'><HiOutlineMail size={16}/></span>
                                    <span>info@rankwebz.com</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className={`container items-center flex justify-between transition-all  ${active ? 'py-2 sm:py-3':' py-3 sm:py-5'}`}>
                    <Link to={'/'} className='inline-block'>
                        <img className={`hidden md:block h-auto transition-all object-cover ${active ? 'w-36':'w-44'}`} src={FullLogo} alt='rankwebx logo'/>
                        <img className={`md:hidden block h-auto transition-all object-cover ${active ? 'w-7':'w-auto'}`} src={LogoIcon} alt='rankwebx logo'/>
                    </Link>
                    <div className='space-x-2'>
                        <button onClick={() => HandelWebAnalysModal('Free Website Analysis')} className={`${active ? 'text-xs md:text-sm':'text-xs sm:text-sm md:text-base'} border btn border-gray-700 bg-white hover:bg-black hover:text-white`}>
                            Free Website Analysis
                        </button>
                        {currentPath?.[1] != '' ? (
                            <Link to={'/'} className={`${active ? 'text-xs md:text-sm':'text-xs sm:text-sm md:text-base'} border btn bg-gradient-coustom text-white`}>
                                <span className='relative z-10'>We’re Hiring</span>
                            </Link>
                        ):(
                            <a href='#componentToScrollTo' className={`${active ? 'text-xs md:text-sm':'text-xs sm:text-sm md:text-base'} border btn bg-gradient-coustom text-white`}>
                                <span className='relative z-10'>We’re Hiring</span>
                            </a>
                        )}
                        
                    </div>
                </div>

                
                
            </header>
        </>
    )
}

export default Header
