import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import { useFormik } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import validator from 'validator';
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';

export default function ContactMoadal({modalOpen, HandelCloseModal, titleText, isDate=false}) {

    const [thankYou, setThankYou] = useState(false);
    const [error, setError] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [btnLoader, setBtnLoader] = useState(false);
    const navigate = useNavigate();

    

    const closeModal = () => {
        HandelCloseModal(false);
    };

    const validate = (values) => {
        const errors = {};
        if(!values.fullname){
            errors.fullname = 'Please enter a name!';
        }

        if (!values.website) {
            errors.website = 'Please enter web address!';
        } else if (!validator.isURL(values.website)) {
            errors.website = 'Please provide a valid web address';
        }

        if(isDate){
            if (!values.scheduledate) {
                errors.scheduledate = 'Please choose a date!';
            }
        }

        if (!values.email) {
            errors.email = 'Please enter email address!';
        } else if (!validator.isEmail(values.email)) {
            errors.email = 'Invalid email address';
        }

       

        if (values.message.match("[<>]")) {
            errors.message = 'Please provide a valid message';
        }

        return errors;
    };

    // console.log(titleText)

    const formik = useFormik({
        initialValues: {
            formtitle: titleText ? titleText:'',
            fullname: '',
            website: '',
            email: '',
            message: '',
            scheduledate: '',
        },
        validate,
        onSubmit: (values, { resetForm }) => {
        setIsSubmit(true);
        setBtnLoader(true);
        // console.log(values)
        // https://works-reddensoft.com/reddensite/api/webanalysisform
        axios.post('https://rankwebz.com/admin/api/storewebanalysis', values)
            .then((res) => {
                setThankYou(true);
                navigate('/thank-you');
                resetForm();
            })
            .catch((errors) => {
                setError(true);
            })
            .finally(() => {
                setIsSubmit(false);
                setBtnLoader(false);
                setTimeout(() => {
                ErrorRemove()
                }, 3000);
            })

        }
    });

    const ErrorRemove = () => {
        setThankYou(false);
        setError(false);
    }

    useEffect(() => {
        if(titleText){
            formik.setFieldValue('formtitle', titleText)
        }
        
    }, [titleText])



    // console.log( formik.values.date.toStri);
    

  return (
    <>
     
      <Transition appear show={modalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 backdrop-blur-sm bg-black/30" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="w-full max-w-xl transform rounded-md bg-white pb-6 text-left align-middle shadow-xl transition-all">
                    <div className='bg-gradient-coustom h-2 rounded-t-lg overflow-hidden'></div>
                    <button onClick={closeModal} className='absolute top-5 right-3'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                  <Dialog.Title
                    as="h3"
                    className="text-lg pt-4 px-6 font-bold leading-6 text-gray-900"
                  >
                    {titleText ? titleText:''}
                  </Dialog.Title>
                  

                  <div className="mt-4 px-6">
                    <form onSubmit={formik.handleSubmit} className='grid sm:grid-cols-2 sm:gap-y-6 gap-y-1 gap-x-4'>
                        <div>
                            <input type={'text'} className="form-control border" 
                                placeholder="Name*" name='fullname'
                                onChange={formik.handleChange}
                                value={formik.values.fullname}
                                onBlur={formik.handleBlur}
                            />
                            {formik.errors.fullname && formik.touched.fullname && (
                                <p className='mt-0.5 text-xs text-red-400'>
                                    {formik.errors.fullname}
                                </p>
                            )}
                        </div>
                        <div>
                            <input type={'email'} className="form-control border" placeholder="Email Address*"  name='email'
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                onBlur={formik.handleBlur}
                            />
                            {formik.errors.email && formik.touched.email && (
                                <p className='mt-0.5 text-xs text-red-400'>
                                {formik.errors.email}
                                </p>
                            )}
                        </div>

                        <div className={isDate ? "":'col-span-full'}>
                            <input type={'text'} 
                                className="form-control border" 
                                name='website'
                                placeholder="Website*" 
                                onChange={formik.handleChange}
                                value={formik.values.website}
                                onBlur={formik.handleBlur}
                            />
                            {formik.errors.website && formik.touched.website && (
                                <p className='mt-0.5 text-xs text-red-400'>
                                    {formik.errors.website}
                                </p>
                            )}
                            
                        </div>

                        {isDate && (
                            <div className=''>
                                <DatePicker
                                    selected={formik.values.scheduledate}
                                    onChange={(date) => formik.setFieldValue('scheduledate', date)}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="time"
                                    className="form-control border"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    placeholderText="Click to select a date*"
                                        minDate={new Date().setDate(new Date().getDate() + 1)}
                                        maxDate={new Date().setMonth(new Date().getMonth() + 1)}
                                    />
                                
                                {formik.errors.scheduledate && formik.touched.scheduledate && (
                                    <p className='mt-0.5 text-xs text-red-400'>
                                    {formik.errors.scheduledate}
                                    </p>
                                )}
                            
                            </div>
                        )}

                        
                        
                    
                        <div className='col-span-full'>
                            <textarea className="form-control border" rows="3" placeholder="Message" name='message'
                            onChange={formik.handleChange}
                            value={formik.values.message}
                            onBlur={formik.handleBlur}
                            >

                            </textarea>
                            {formik.errors.message && formik.touched.message && (
                                <p className='mt-0.5 text-xs text-red-400'>
                                {formik.errors.message}
                                </p>
                            )}
                        </div>
                        {thankYou && (
                            <div className='px-5 py-2 text-base font-bold text-center text-green-500 bg-green-50 border border-green-400 rounded col-span-full'>
                            Thanks for getting in touch!
                            </div>
                        )}

                        {error && (
                            <div className='px-5 py-2 text-base font-bold text-center text-red-500 bg-white rounded col-span-full'>
                            Network Error
                            </div>
                        )}

                            <div className='col-span-full'>
                                <button disabled={isSubmit} type='submit' 
                                className='h-12 text-center text-white inline-flex bg-gradient-coustom shadow-sm btn sm:mt-3 hover:bg-primary hover:text-white'>
                                    
                                    <span className='inline-flex'>
                                        <span className='flex-1 items-center'>
                                            Send Now
                                        </span>
                                        {btnLoader && (
                                            <span className='animate-spin h-5 w-5 ml-3 inline-block border-white rounded-full border-b-0 border-2'></span>
                                        )}
                                    </span>
                                    
                                </button>
                            </div>
                        </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
