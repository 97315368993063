import { Transition } from '@headlessui/react';
import React, { createRef, useState } from 'react';
import ContactMoadal from '../Widgets/ContactModal';
import Footer from './Footer';
import Header from './Header';


export default function Layout({ children}) {
  const [headerActive, setHeaderActive] = useState(false);
  const [stickyBtn, setstickyBtn] = useState(false);
  const scrollposition = createRef();
  const [isScrollTop, setIsScrollTop] = useState(false);
  const [title, setTitle] = useState('');
  const [isDate, setIsDate] = useState(false);

  const [websiteAnalysisModal, setWebsiteAnalysisModal] = useState(false);
    
    const HandelWebAnalysModal = (val) => {
      setWebsiteAnalysisModal(true);
      setTitle(val);
      setIsDate(false);
    } 
    const HandelCloseModal = (val) => {
      setWebsiteAnalysisModal(val);
    }

    const HandelScheduleModal = (val) => {
      setWebsiteAnalysisModal(true);
      setTitle(val);
      setIsDate(true);
    }

    const HandelScroll = () => {
      let getPosition = scrollposition.current.getBoundingClientRect();
      if(getPosition.y < -10){
        setHeaderActive(true)
      } else {
        setHeaderActive(false)
      }
      if(getPosition.y < -380){
        setstickyBtn(true)
      } else {
        setstickyBtn(false)
      }

      if(getPosition.y < -1000){
        setIsScrollTop(true)
      } else {
        setIsScrollTop(false)
      }
    }


  return (
    <section onScroll={() => HandelScroll()} className='h-screen scroll-smooth scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent scrollbar-thumb-rounded-md scrollbar-track-rounded-md'>
        
      <Transition
          show={stickyBtn}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
        <button onClick={() => HandelScheduleModal('Schedule a discovery')} className='fixed z-20 shadow-xl border border-white/20 inline-block text-lg -rotate-90 text-white top-1/2 -right-24 px-8 h-12 bg-black rounded-t-3xl'>
          <span className='inline-block'>Schedule a discovery</span>
        </button>
      </Transition>

      <Header active={headerActive} HandelWebAnalysModal={HandelWebAnalysModal}/>

      <section ref={scrollposition}>
          {children}
      </section>
        
      <Footer HandelWebAnalysModal={HandelWebAnalysModal}/>
    
      <Transition
          show={isScrollTop}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className='w-10 h-10 fixed z-20 bottom-2 right-2'>
            <a href='#banner' className='w-full flex justify-center items-center h-full rounded-t-2xl overflow-hidden text-white rounded-bl-2xl bg-gradient-coustom'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="w-5 h-5 relative z-10">
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
              </svg>
            </a>
          </div>
      </Transition>

      <ContactMoadal isDate={isDate} modalOpen={websiteAnalysisModal} titleText={title} HandelCloseModal={HandelCloseModal}/>

    </section>
  )
}

